import { notificationSystem } from '@/assets/notifications.json';

export default {
  data: () => ({
    notificationSystem,
  }),
  methods: {
    messageSuccess(text) {
      this.$toast.success(text, 'Feito', this.notificationSystem.options.success);
    },

    messageError(text) {
      this.$toast.error(text, 'Erro', this.notificationSystem.options.error);
    },

    messageInfo(text) {
      this.$toast.info(text, 'Atenção', this.notificationSystem.options.error);
    },
  },
};
