import { notificationSystem } from '@/assets/notifications.json';

export default {
  data: () => ({
    notificationSystem,
  }),
  methods: {
    showErrors(error) {
      const { data } = error.response;
      const errors = data.errors ? data.errors : data;
      let msg = '';

      if (typeof errors === 'string') {
        msg = errors;
      }

      if (typeof errors === 'object') {
        Object.keys(errors).forEach((it) => {
          msg += `<span class="block">${errors[it]}</span>`;
        });
      }

      if (!msg) {
        msg = 'Erro inesperado';
      }

      this.$toast.error(msg, 'Erro!', this.notificationSystem.options.error);
    },
  },
};
