import http from '@/http';

export default class HelperService {
  static getProvinces() {
    return http.get('provinces');
  }

  static getSolar() {
    const config = {
      headers: {
        'api-access-key': 'RYQCBKFDKBREPDEX',
        'api-secret-key': '-$x=Po2LdW!_Sd=wttcJBTr|Gl8ao^ba',
      },
    };
    return http.get('https://hmle2-marketplace-api.solfacil.com.br/third-party/products?min_power=3370&max_power=3370&page=1&page_size=5&roof_types[0]=fibrocimento', config);
  }

  static getCities(params) {
    return http.get('cities', { params });
  }

  // eslint-disable-next-line no-unused-vars
  static async getCep(params) {
    const { data } = await http.get('cep', { params });
    return data;
  }

  static removeAcentos(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
