<template>
  <div id="container-login">
    <div class="row h-100" id="desktop">
      <div class="col-md-6 h-100 img-login">
          <img src="@/assets/images/img-login.png" class="h-100" alt="">
          <img src="@/assets/images/logo.png" id="logo-login" alt="">
      </div>
      <div class="col-md-6 d-flex align-items-center">
        <div class="w-100" id="container-form">
          <h2>
            Mais alguns cliques para fazer <br> login em sua conta.
          </h2>
          <form class="w-100 mt-5" ref="form" @submit.stop.prevent="login">
            <input class="form-control" type="text" v-model="payload.email" placeholder="Email">
            <input class="form-control mt-2" type="password" placeholder="Senha" v-model="payload.password">
            <button type="submit" :disabled="load">
              <b-spinner small class="mr-2" v-show="load"></b-spinner> Entrar
            </button>
          </form>
        </div>
      </div>
    </div>

    <div id="mobile" class="h-100 d-flex justify-content-center align-items-center">
      <div class="col-lg-10 mx-auto">
        <div class="d-flex w-100 justify-content-center">
          <img src="@/assets/images/logo-mobile.png" id="logo-mobile" alt="">
        </div>
        <form class="w-100 mt-5" ref="form" @submit.stop.prevent="login">
            <input class="form-control" type="text" v-model="payload.email" placeholder="Email">
            <input class="form-control mt-2" type="password" placeholder="Senha" v-model="payload.password">
            <button type="submit" class="w-100" :disabled="load">
              <b-spinner small class="mr-2" v-show="load"></b-spinner> Entrar
            </button>
          </form>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
h2 {
 color: #666;
}
.img-login {
  overflow: auto;
}

#logo-mobile {
  width: 200px;
}

#logo-login {
  position: absolute;
  top: 30px;
  left: 40px;
}
#container-form {
  padding: 0 100px;
}
#container-login {
  height: 100%;
  background: #fff;
}

#content-form{
  color: #333;
}

#mobile {
  background: url(../../assets/images/bg-mobile.png);
  display: none !important;
}

button{
  border: none;
  background: #ff7b11;
  color: #fff;
  font-size: 20px !important;
  padding: 10px 30px;
  margin-top: 20px !important;
}
input{
  padding: 25px 10px !important;
}

@media screen and (max-width: 768px) {
  #desktop {
    display: none;
  }
  #mobile {
    display: flex !important;
  }
}
</style>

<script>
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */

import LoginService from '@/Services/LoginService';
import AgentService from '@/Services/AgentService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';

export default {
  data() {
    return {
      payload: {
        email: null,
        password: null,
      },
      load: false,
      agents: {},
    };
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    async login() {
      this.load = true;
      try {
        const { data } = await LoginService.login(this.payload);
        LoginService.saveUser(data.user);
        LoginService.saveToken(data.token);
        LoginService.savePermission(data.permission);
        this.$router.push({ name: 'projects' });
        this.messageSuccess('Login realizado com sucesso');
      } catch (error) {
        console.log(error);
        this.messageError('Usuário ou senha inválidos');
      }
      this.load = false;
    },
    async getList() {
      const { data } = await AgentService.getList();
      this.agents = data.map((item) => ({
        id: item.id,
        name: item.name,
        email: item.email,
        contact: item.phone_mobile,
        city: item.address.city.name,
        province: item.address.city.province.name,
      }));
    },
  },
};
</script>
