import { notificationSystem } from '@/assets/notifications.json';

export default {
  data: () => ({
    notificationSystem,
  }),
  methods: {
    getQuestionObj(method, redirect = '', msg) {
      return {
        timeout: 15000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><strong>Sim</strong></button>', async (instance, toast) => {
            method();

            if (redirect.length > 0) {
              this.$router.push({ name: redirect });
            }

            this.$toast.success(`${msg} com sucesso`, 'Feito!', this.notificationSystem.options.success);
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          }, true],
          ['<button>Não</button>', (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          }],
        ],
      };
    },
  },
};
