<template>
  <div id="nav">
    <div class="row">
      <navbar-left :menuHide="menuHide" v-if="show"/>
      <div id="main" class="px-5 mt-3" :class="{ 'main-full': menuHide }">
        <div class="d-flex justify-content-between" id="menu-top-mobile">
        <button @click="toggleMenu" id="btn-menu">
          <i class="fas fa-bars"></i>
        </button>
          <div>
            <span>Bem vindo <strong>{{ user.name }}</strong> </span>
            <b-button variant="danger" @click="logout" class="ml-3"><i class="fas fa-sign-out-alt"></i> Sair</b-button>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <h4 style="margin-left: -15px">{{ $route.meta.title }}</h4>
          <!-- <b-button variant="default" @click="$router.go(-1)">
            <i class="fas fa-arrow-left"></i> Voltar
          </b-button> -->
        </div>
        <div class="w-100 mt-3">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import LoginService from '@/Services/LoginService';

export default {
  components: {
    'navbar-left': () => import('@/layout/menu-left'),
  },
  data() {
    return {
      // eslint-disable-next-line no-unneeded-ternary
      menuHide: window.innerWidth > 1366 ? false : true,
      user: JSON.parse(localStorage.getItem('user')),
      rotate: false,
      mobile: false,
      show: false,
      load: false,
    };
  },
  methods: {
    isMobile() {
      // eslint-disable-next-line no-restricted-globals
      if (screen.width <= 760) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },

    logout() {
      LoginService.logout();
      this.$router.replace('/login');
    },

    toggleMenu() {
      this.menuHide = !this.menuHide;
      this.rotate = !this.rotate;
    },
  },

  watch: {
    $route() {
      if (window.innerWidth > 1366) {
        this.menuHide = false;
      } else {
        this.menuHide = true;
      }
    },
  },
  created() {
    this.isMobile();
    this.show = true;
  },
};
</script>
<style lang="css">
@media screen and (max-width: 768px) {
  #menu-top-mobile{
    width: 109% !important;
  }
}
#menu-top-mobile{
    width: 103%;
    margin-left: -14px;
    margin-bottom: 30px;
  }
#btn-menu {
  background: #fe7900;
  border: none;
  padding: 0 15px;
  color: #fff;
}
</style>
