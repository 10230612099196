/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Router from 'vue-router';
import LoginService from '@/Services/LoginService';
import Login from '@/components/global/login.vue';
// import { AclRule } from 'vue-acl';
import Home from './views/Home.vue';

Vue.use(Router);

export const children = [
  {
    path: '/agentes',
    name: 'agents',
    component: () => import('@/components/pages/agents/list-agents'),
    meta: {
      title: 'Agentes de venda',
      rule: 'isAdmin',
    },
  },
  {
    path: '/agentes/adicionar',
    name: 'agent-create',
    component: () => import('@/components/pages/agents/create-agent'),
    meta: {
      rule: 'isAdmin',
      title: 'Adicionar novo agente de vendas',
    },
  },
  {
    path: '/agentes/editar/:id',
    name: 'agents-edit',
    props: true,
    component: () => import('@/components/pages/agents/create-agent'),
    meta: {
      rule: 'isAdmin',
      title: 'Adicionar novo agente de vendas',
    },
  },
  {
    path: '/clientes',
    name: 'clients',
    component: () => import('@/components/pages/clients/list-clients'),
    meta: {
      rule: 'isPublic',
      title: 'Clientes',
      menu: 'clients',
    },
  },
  {
    path: '/clientes/adicionar',
    name: 'clients-create',
    component: () => import('@/components/pages/clients/create-client'),
    meta: {
      rule: 'isPublic',
      title: 'Adicionar novo cliente',
      menu: 'clients',
    },
  },
  {
    path: '/clientes/editar/:id',
    name: 'client-edit',
    props: true,
    component: () => import('@/components/pages/clients/create-client'),
    meta: {
      rule: 'isPublic',
      title: 'Editar cliente',
    },
  },
  {
    path: '/projeto/adicionar',
    name: 'project-create',
    component: () => import('@/components/pages/project/create-project'),
    meta: {
      rule: 'isPublic',
      title: 'Adicionar novo pré projeto',
      menu: 'projects',
    },
  },
  {
    path: '/projeto/adicionar/:client_id',
    name: 'project-create-with-client',
    props: true,
    component: () => import('@/components/pages/project/create-project'),
    meta: {
      rule: 'isPublic',
      title: 'Adicionar novo pré projeto',
    },
  },
  {
    path: '/projetos',
    name: 'projects',
    component: () => import('@/components/pages/project/list-projects'),
    meta: {
      rule: 'isPublic',
      title: 'Pré Projetos',
      menu: 'projects',
    },
  },
  {
    path: '/projeto/:id',
    name: 'project',
    props: true,
    component: () => import('@/components/pages/project/project'),
    meta: {
      rule: 'isPublic',
      title: 'Pré Projeto',
    },
  },
  {
    path: '/projeto-customizado/:id',
    name: 'project-custon',
    props: true,
    component: () => import('@/components/pages/project/project-custon'),
    meta: {
      rule: 'isPublic',
      title: 'Pré Projeto',
    },
  },
  {
    path: '/projeto/:project/editar-kit',
    name: 'project-edit-kit',
    props: true,
    component: () => import('@/components/pages/project/edit-kit'),
    meta: {
      rule: 'isPublic',
      title: 'Editar Kit',
    },
  },
  {
    path: '/vistorias',
    name: 'inspections',
    component: () => import('@/components/pages/inspections/list-inspections.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Vistorias Técnicas',
    },
  },
  {
    path: '/vistoria/:id',
    props: true,
    name: 'inspection-edit',
    component: () => import('@/components/pages/inspections/inspection.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Vistoria Técnica',
    },
  },
  {
    path: '/pre-vistoria/:id',
    props: true,
    name: 'pre-inspection',
    component: () => import('@/components/pages/inspections/pre-inspection.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Pré Vistoria',
    },
  },
  {
    path: '/financiamentos',
    name: 'financings',
    component: () => import('@/components/pages/financings/list-financings.vue'),
    meta: {
      rule: 'isPublic',
      title: 'Financiamentos',
    },
  },
  {
    path: '/usuarios',
    name: 'users',
    component: () => import('@/components/pages/users/list-users'),
    meta: {
      rule: 'isAdmin',
      title: 'Usuários cadastrados',
    },
  },
  {
    path: '/administrativo',
    name: 'admin',
    component: () => import('@/components/pages/admin/admin.vue'),
    meta: {
      rule: 'isAdmin',
      title: 'Administrativo',
    },
  },
];

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes,
// });
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Home,
      name: 'home',
      meta: {
        rule: 'isPublic',
      },
      children,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        rule: 'isPublic',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = LoginService.getToken();

  if (!token && to.name !== 'login') {
    return next({ name: 'login' });
  }

  if (token && (to.name === 'login' || to.fullPath === '/login')) {
    return next({ path: '/' });
  }

  if (token) {
    const decoded = LoginService.getDecodedToken();

    if (!decoded) {
      LoginService.errorDecoded();

      return next({ name: 'login' });
    }
  }

  return next();
});

export default router;
