<template>
  <div id="hover" v-if="enable" class="d-flex justify-content-center align-items-center">
      <img src="@/assets/images/loading.gif" alt="">
  </div>
</template>

<script>
export default {
  props: {
    enable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style lang="css">
  #hover{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #000000b3;
    z-index: 9999;
  }

  #hover img{
      width: 100px;
      height: 100px;
  }
</style>
