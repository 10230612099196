<template>
  <b-form-group label="CEP" class="position">
    <b-form-input
    type="text"
    v-model="cep"
    :disabled="load"
    v-mask="'#####-###'"
    @blur="find" />
    <b-spinner class="loader" v-if="load"></b-spinner>
    <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank" rel="noopener noreferrer">
      Não sei o cep <i class="fas fa-exclamation-circle"></i>
    </a>
  </b-form-group>
</template>

<script>
import HelperService from '@/Services/HelperService';
import MessagesMixin from '@/mixins/messages-mixin';

export default {
  props: ['zip_code'],
  data() {
    return {
      data: {},
      load: false,
      cep: '',
    };
  },
  mixins: [MessagesMixin],
  methods: {
    async find() {
      this.load = true;
      const params = {
        cep: this.cep,
      };
      if (this.cep.length === 9) {
        try {
          this.data = await HelperService.getCep(params);
          this.$emit('data', this.data);
        } catch (error) {
          console.log(error);
          this.messageError('Não foi possível encontrar o CEP digitado');
        }
      }
      this.load = false;
    },
  },
  created() {
    if (this.zip_code) this.cep = this.zip_code;
  },
};
</script>
<style lang="css" scoped>
  .loader {
    top: 10px;
    right: 25px;
    position: absolute;
  }
  a {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  a i{
    margin-left: 5px;
    margin-top: 2px;
  }
</style>
