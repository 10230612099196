import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';
import VueIziToast from 'vue-izitoast';
import Vue2Filters from 'vue2-filters';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'izitoast/dist/css/iziToast.css';
import 'vue-advanced-search/dist/AdvancedSearch.css';
import Loading from '@/components/global/loading.vue';
import SearchCep from '@/components/global/cep.vue';
import acl from './acl';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

Vue.component('loading', Loading);
Vue.component('searchCep', SearchCep);

Vue.config.productionTip = true;

const Vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false,
  },
  number: {
    format: '0',
    thousandsSeparator: ',',
    decimalSeparator: '.',
  },
  currency: {
    symbol: 'R$ ',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false,
  },
  pluralize: {
    includeNumber: false,
  },
  ordinal: {
    includeNumber: false,
  },
};

Vue.use(require('vue-moment'));

Vue.use(Vue2Filters, Vue2FiltersConfig);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VueIziToast);

new Vue({
  router,
  store,
  acl,
  render: (h) => h(App),
}).$mount('#app');
