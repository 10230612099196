/* eslint-disable class-methods-use-this */
import Service from './Service';

class AgentService extends Service {
  constructor() {
    super('agents');
  }

  getFieldsTableAgents() {
    return [
      {
        key: 'name',
        label: 'Nome',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'contact',
        label: 'Contato',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'city',
        label: 'Cidade',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'province',
        label: 'UF',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        key: 'actions',
        label: 'Ações',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
    ];
  }
}

const agentService = new AgentService();
export default agentService;
